
import lib from '@/api/co.lib'
import web from '@/api/web/co.web'

const toContent = function ({
  that,
  pagename = '',
  item = {},
  category = null
}) {
  const params = {
    name: category.name,
    contentId: item.contentId,
    type: item.type,
    title: item.title,
    circleId: item.circleId,
    categoryId: item.categoryId
  }

  const name = pagename
  lib.cookie.set(name, JSON.stringify(params))
  that.$router.push({ name })
}

const toContents = function ({
  that,
  pagename = '',
  item = {},
  category = null
}) {
  if (!category) {
    return
  }

  const params = {
    name: category.name,
    title: category.title,
    type: category.type,
    circleId: category.circleId,
    categoryId: category.categoryId,
    indicator: category.indicator
  }
  const name = pagename
  lib.cookie.set(name, JSON.stringify(params))
  that.$router.push({ name })
}

const Actions = {
  content: toContent,
  contents: toContents
}

const ContentTypes = [
  web.comm.ContentTypes.IMAGE,
  web.comm.ContentTypes.VIDEO,
  web.comm.ContentTypes.ARTICLE,
  web.comm.ContentTypes.ACTIVITY
]

const coAction = {
  navigate ({
    that,
    pagename = '',
    item = {},
    category = null
  }) {
    let type = item.type || ''
    if (ContentTypes.indexOf(type) !== -1) {
      type = 'content'
    }
    const action = Actions[type] || null
    if (typeof action === 'function') {
      action({ that, pagename, item, category })
    }
  }
}

export default coAction
