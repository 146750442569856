
import api from '@/api/co.api'
// import lib from '@/api/co.lib'
import act from '@/api/co.action'
// import web from '@/api/web/co.web'

import mixCircleCatalog from '@/pages/mixins/circle/mix.circle.catalog'

export default {
  mixins: [
    mixCircleCatalog
  ],
  data () {
    return {
      btnSub: {
        text: '订阅',
        icon: 'mdi-plus'
      }
    }
  },
  methods: {
    onContent (ev, category) {
      act.navigate({
        that: this,
        pagename: '',
        item: ev.item,
        category
      })
    },
    toContents (ev, category) {
      act.navigate({
        that: this,
        pagename: '',
        item: {
          type: 'contents'
        },
        category
      })
    },
    changePagination (number, name) {
      const cat = this.getCatalog(name)
      const params = cat.params
      const data = cat.params.data
      const index = parseInt(number, 10) || 1

      cat.pagination.current = index
      data.currentPage = index
      params.reload = true
      api.httpx.getItems(params)
    },
  }
}
