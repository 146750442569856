
import api from '@/api/co.api'
import web from '@/api/web/co.web'

const NavHome = {
  text: '首页',
  disabled: false,
  href: '/',
}

const NavSelf = {
  text: '圈子',
  disabled: true,
  href: '/circle',
}

const NavCurrent = {
  text: '全部',
  disabled: true,
  href: '',
}

export default {
  data () {
    return {
      navigated: true,
      navHome: NavHome,
      navSelf: NavSelf,
      navCrumbs: [
        NavHome,
        NavSelf,
        NavCurrent
      ],
      navPage: {
        current: 1,
        length: 1,
        totalVisible: 7
      },
      catCur: {},
      catDef: {
        name: 'cr_all',
        circleId: '',
        avatar: 'https://oss.landcoo.com/yujian/1647446218328117248.png',
        cover: 'https://oss.landcoo.com/yujian/1646798120778076160.jpg',
        title: '全部',
        navigated: false,
        params: null,
        items: [],
        total: 0,
        pagination: {
          current: 1,
          length: 1,
          totalVisible: 7
        },
      },
      catMy: {
        title: '我的订阅',
        name: 'my',
        active: 0,
        params: null,
        items: []
      },
      catAll: {
        title: '所有圈子',
        name: 'all',
        active: 0,
        params: null,
        items: []
      }
    }
  },
  methods: {
    onCatalog (ev) {
      this.toCatalog(ev.item)
    },
    toCatalog (item) {
      this.focusCatalog(item)
      this.activeCatalog(item.name)
      this.loadData(item.name)
    },
    getCatalog (name) {
      let result = null
      const cats = this.catAll.items
      for (const i in cats) {
        const cat = cats[i]
        if (cat.name === name) {
          result = cat
          break
        }
      }
      return result
    },
    focusCatalog (item) {
      this.navCrumbs = []
      this.navCrumbs.push(
        { ...this.navHome },
        { ...this.navSelf },
        {
          text: item.title,
          disabled: true,
          href: '',
        }
      )

      this.catCur = this.getCatalog(item.name)
      Object.assign(this.navPage, this.catCur.pagination)
      this.navigated = item.navigated
    },
    activeCatalog (name) {
      let result = 0
      const cnt = this.catAll.items.length
      for (let i = 0; i < cnt; i++) {
        const item = this.catAll.items[i]
        if (item.name === name) {
          result = i
          break
        }
      }

      this.catAll.active = result
    },
    configMyCircles () {
    },
    loadMyCircles (reload = true) {
    },
    configCatalogParams (catalog = null) {
      // const me = this
      if (!catalog) {
        return
      }

      const executing = function () {}
      const executed = function (res) {
        catalog.total = res.total
        const data = catalog.params.data
        const pagination = catalog.pagination
        const pageSize = parseInt(data.pageSize) || 12
        pagination.length = Math.ceil(res.total / pageSize)
      }

      catalog.params = web.content.getParamsAllCircle({
        circleId: catalog.circleId,
        caches: catalog.items,
        executing,
        executed
      })
    },
    configCatalogs () {
      const obj = this.catAll
      const cnt = obj.items.length
      for (let i = 1; i < cnt; i++) {
        const cat = obj.items[i]
        this.configCatalogParams(cat)
      }
    },
    configAllCircles () {
      const me = this
      const obj = this.catAll
      const executed = function (res) {
        // console.log('params.items: %o', obj.params.items)
        if (res.status) {
          const list = obj.params.items || []
          for (const i in list) {
            const src = list[i]
            const dst = {
              name: `cr_${src.circleId}`,
              circleId: src.circleId,
              cover: src.cover || '',
              avatar: src.icon || '',
              title: src.title,
              navigated: false,
              params: null,
              items: [],
              total: 0,
              pagination: {
                current: 1,
                length: 1,
                totalVisible: 7
              },
            }
            obj.items.push(dst)
          }
        }

        me.configCatalogs()
      }

      obj.params = web.circle.getParams({
        sort: 'circleId',
        order: 'asc',
        executed
      })
    },
    loadAllCircles (reload = true) {
      const obj = this.catAll
      obj.params.reload = reload
      api.httpx.getItems(obj.params)
    },
    loadCatalogContents (catalog = null, reload = true) {
      if (!catalog) {
        return
      }
      const params = catalog.params
      if (params) {
        params.reload = reload
        params.initial = true
        api.httpx.getItems(params)
      }
    },
    loadData (name = '', reload = true) {
      const nm = name || 'cr_all'
      const cat = this.getCatalog(nm)
      this.loadCatalogContents(cat, reload)
    },
    configCircles () {
      const obj = this.catAll
      obj.items.splice(0, obj.items.length)
      obj.items.push(this.catDef)

      this.configCatalogParams(this.catDef)

      this.configMyCircles()
      this.configAllCircles()
      this.loadMyCircles()
      this.loadAllCircles()

      this.toCatalog(this.catDef)
    }
  }
}
