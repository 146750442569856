<template>
  <section>
    <!-- <land-banner
      :items="banItems"
      :height="classify.height"
      :points="classify.points"
      :cycle="classify.cycle"
      :interval="classify.interval"
      :hide-delimiters="wdHideDelimiters"
      :show-arrows="wdShowArrows"
    /> -->
    <land-section
      id="noticecenter"
      space="30"
      color="grey lighten-4"
    >
      <v-container
        style="max-width: 1200px;"
      >
        <v-row
          no-gutters
          justify="center"
        >
          <v-col
            cols="12"
            md="3"
            class="hidden-sm-and-down"
          >
            <div class="d-flex flex-column white pl-6 pt-6 mr-8">
              <land-categories
                :title="catAll.title"
                :items="catAll.items"
                active-color="grey lighten-4"
                @click="onCatalog"
              />
            </div>
          </v-col>
          <v-col
            cols="12"
            md="9"
          >
            <land-img
              v-if="catCur.cover"
              class="rounded"
              height="160"
              gradient="to top right, rgba(5, 11, 31, 0.45), rgba(5, 11, 31, 0.60)"
              :src="catCur.cover"
              flat
              max-width="100%"
              tile
            >
              <div
                class="d-flex flex-column justify-center px-10 co-h-full"
              >
                <div class="d-flex flex-row">
                  <v-avatar
                    v-if="catCur.avatar"
                    class="rounded"
                    size="96"
                    rounded="0"
                    variant="outlined"
                  >
                    <v-img
                      :src="catCur.avatar"
                    />
                  </v-avatar>
                  <div
                    class="d-flex flex-column ml-6"
                  >
                    <span class="text-h6 white--text">
                      {{ catCur.title }}
                    </span>
                    <span class="text-body-2 white--text">
                      {{ `${catCur.total} 张图片` }}
                    </span>
                    <div
                      v-if="catCur.circleId"
                      class="co-flex-row co-justify-center co-items-center mt-3 py-1 px-4 white co-round"
                    >
                      <v-icon
                        size="medium"
                        color="primary"
                      >
                        {{ btnSub.icon }}
                      </v-icon>
                      <span class="text-caption primary--text pl-1">
                        {{ btnSub.text }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </land-img>

            <div
              class="d-flex flex-row justify-space-between align-center pt-6 ml-4"
            >
              <v-breadcrumbs
                :items="navCrumbs"
                large
                class="pa-0"
              />
              <v-app-bar-nav-icon
                class="hidden-md-and-up"
                @click="drawer = !drawer"
              />
            </div>

            <v-row
              class="mt-2"
            >
              <v-col
                cols="12"
              >
                <land-gallery
                  :items="catCur.items"
                  tooled
                  @click="onContent($event, catCur)"
                />
                <div class="d-flex flex-row mt-4">
                  <v-pagination
                    v-model="navPage.current"
                    :length="navPage.length"
                    :total-visible="navPage.totalVisible"
                    @input="changePagination($event, catCur.name)"
                  />
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <v-navigation-drawer
        v-model="drawer"
        fixed
        overlay-color="secondary"
        overlay-opacity=".8"
        temporary
      >
        <v-list>
          <v-subheader>{{ catAll.title }}</v-subheader>
          <v-list-item-group
            v-model="catAll.active"
          >
            <v-list-item
              v-for="(item, index) in catAll.items"
              :key="index"
            >
              <v-list-item-content>
                <v-list-item-title
                  class="text-subtitle-1"
                >
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </land-section>

    <section-foot />
  </section>
</template>

<script>

  import mixCircleBanner from '@/pages/mixins/circle/mix.circle.banner'
  import mixCircleBoot from '@/pages/mixins/circle/mix.circle.boot'

  export default {
    metaInfo: { title: '圈子' },
    components: {
      SectionFoot: () => import('@/pages/sections/Foot.vue')
    },
    mixins: [
      mixCircleBanner,
      mixCircleBoot
    ],
    data () {
      return {
        drawer: false,
      }
    },
    created () {
      // this.initBannerParams({})
      // this.loadBanners()

      this.configCircles()
    },
    methods: {
    }
  }
</script>
